import moment from 'moment';

export const toDate = (date) => new Date(date * 1000);

export const dateToUnix = (date) => new Date(date).getTime() / 1000;

export const getDateFormatDDMMYYY = (date) => moment(date,'DD/MM/YYYY', true);

export const getDateFormatDDMMYYYDate = (date) => moment(date,'DD/MM/YYYY', true).toDate();

export const getDateFormat = (date) => moment.unix(date).format('LT DD/MM/YYYY');


export const getDateFormatMXN = (date) => moment(date).format('DD/MM/YYYY');

export const isOnline = (date) => {
    const end = moment.unix(date);
    const now = moment(new Date());
    const duration = moment.duration(now.diff(end));
    const hours = duration.asHours();
    return hours < 6;
};
