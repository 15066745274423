import * as actionTypes from '../constantes/request';


export const actionFetchData = () => ({
  type: actionTypes.FETCHING_DATA,
});

export const actionFetchDataSuccess = payload => ({
  type: actionTypes.FETCHING_DATA_SUCCESS,
  payload,
});

export const actionFetchDataFailure = () => ({
  type: actionTypes.FETCHING_DATA_FAILURE,
});
