import history from '../../routes/redirect/history';
import axios from 'axios';

export const request = (method = 'get', url, params = {} ) => {
    return axios(`${process.env.REACT_APP_SERVER}${url}`,{
        method,
        params,
        withCredentials: true
    })
        .then(response => response)
        .catch(error => {
            history.push('/auth/signin/');
            window.location.reload();
            throw error;
        })
};
