/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import routes from '../routes/route';

require('moment/locale/es.js');

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader,
});

// eslint-disable-next-line react/prefer-stateless-function
class App extends Component {
  render() {
    const menu = routes.map((route, index) => ((route.component) ? (
      // eslint-disable-next-line react/jsx-filename-extension
      <Route
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => (
          <route.component {...props} />
        )}
      />
    ) : (null)));

    return (
      <Aux>
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {menu}
              <Route path="/" component={AdminLayout} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

export default App;
