import React from 'react';

const SignInContainer = React.lazy(() => import('../Authentication/SignInContainer'));

const route = [
  {
    path: '/auth/signin', exact: true, name: 'Signin 1', component: SignInContainer,
  },
];

export default route;
