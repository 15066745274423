import {request} from './request';

export const apiDevicesDetails = () => {
    return request('get', `devices/details`,{})
        .then((response) => response.data)
        .catch(error => error)
};


export const apiDevicesAdd = (id) => {
    return request('put', `devices/${id}`,{})
        .then((response) => response.data)
        .catch(error => error)
};


export const apiDevicesDelete = (id) => {
    return request('delete', `devices/${id}`,{})
        .then((response) => response.data)
        .catch(error => error)
};


export const apiDevicesUpdate = (id, params) => {
    return request('put', `devices/${id}/details`, params)
        .then((response) => response.data)
        .catch(error => error)
};


