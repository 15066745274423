import sensorEnergia from '../../assets/images/devices/ENERGIA.svg'
import sensorFoco from '../../assets/images/devices/FOCO.svg'
import sensorGota from '../../assets/images/devices/GOTA.svg'
import sensorTanque from '../../assets/images/devices/TANQUE.svg'
import sensorTermo from '../../assets/images/devices/TERMO.svg'
import moment from 'moment';

const BATTERY_LIFE_MONTHS = 12;

export const parseDevices = (devices) => {
    let _parseDevices = [];

    Object.keys(devices).forEach((device, index) => {
        const last_date = Object.keys(devices[device])[0] || 0;
        const last_value = devices[device][last_date] || 0;
        _parseDevices.push({
            name: devices[device].name || device,
            id: device,
            alert:devices.alert || 0,
            last: {
                date: last_date,
                value: last_value
            },
            dimentions: {
                diameter: devices[device].diameter,
                capacity: devices[device].capacity,
                height: devices[device].height
            },
            battery: devices[device].battery,
            type: devices[device].type || 'regular'
        });
        _parseDevices[index].img = getTypeSensor(device);
        _parseDevices[index].battery = {
            percentage: getPercentBatteryFromDate(devices[device].battery),
            date: devices[device].battery
        };
    });

    return _parseDevices;
};


export const getPercentBatteryFromDate = (date) => {
    const value = moment(new Date()).diff(moment(date,'DD/MM/YYYY'), 'months', true);
    let percent = 100 - (Math.round((value / BATTERY_LIFE_MONTHS)*100));
    percent = percent < 0 ? 0 : percent;
    percent = percent > 100 ? 100 : percent;
    return percent;
};

export  const getDeviceFromId = (devices, id) => {
    let _device = {};
    for (let i = 0; i < devices.length; i++) {
        const device = devices[i];
        if (device.id === id) {
            _device = device;
        }
    }
    return _device;
};


export const getTypeSensor = (sensor) => {
    let imgSensor =  sensorFoco;
    if (isSensor(sensor, 'T')){
        imgSensor = sensorGota
    } else if (isSensor(sensor, 'C')) {
        imgSensor = sensorGota
    } else if (isSensor(sensor, 'B')) {
        imgSensor = sensorTermo
    } else if (isSensor(sensor, 'G')) {
        imgSensor = sensorTanque
    } else if (isSensor(sensor, 'E')) {
        imgSensor = sensorEnergia
    }
    return imgSensor;
};

export const getSuffixSensor = (sensor) => {
    let suffix =  '';
    if (isSensor(sensor, 'T') ||isSensor(sensor, 'C')){
        suffix = 'L'
    } else if (isSensor(sensor, 'B')) {
        suffix = '°'
    } else if (isSensor(sensor, 'G')) {
        suffix = '%'
    } else if (isSensor(sensor, 'E')) {
        suffix = 'kWh'
    }
    return suffix;
};

export const isSensor = (sensor, character) => sensor && sensor.charAt(0) === character;



export const convertToLiters = (volume, height, capacity, diameter) => {
    const liters =
        (
            ((Math.PI * Math.pow((diameter/2),2))/1000)
            *
            (height-volume)
        ) ;
    return truncateToTwoDecimals(liters,2)
};

export const truncateToTwoDecimals = ( x, n) => {
    if(x){
        const v = (typeof x === 'string' ? x : x.toString()).split('.');
        if (n <= 0) return v[0];
        let f = v[1] || '';
        if (f.length > n) return `${v[0]}.${f.substr(0,n)}`;
        while (f.length < n) f += '0';
        return `${v[0]}.${f}`
    }
    return 0;
};

export const getPercentWater = (valueLiters, capacity) => {
    valueLiters *=  100;
    valueLiters /=  capacity;
    return valueLiters;
};

export const getPercentGas = (percent, total) => ( percent * total ) / 100;

export const getPercentWatherToLiters = (value, height) => 100 - (( value * 100 ) / height) ;

export const getLitersPercent = (percent, capacity) => ( percent * capacity ) / 100;


export const getPercentWaterHistory = (history) => {
    let percent = 0;
    for (let i = 0; i < history.length; i++) {
        const data = Number(history[i].value);
        percent += data
    }
    return percent / history.length;
};


export const getMaxWaterHistory = (history) => {
    let day = history[0];
    for (let i = 0; i < history.length; i++) {
        const data = Number(history[i].value);
        if (Number(day.value) > data) {
            day = history[i]
        }
    }
    return day;
};