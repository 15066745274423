import {request} from './request';


export const activityDeviceRange = (device, start, end) => {

    const params = {
        start,
        end
    };

    return request('get', `devices/${device}/activity`, params)
        .then(response => response.data.activity || [])
        .catch(error => error)
};


export const activityDeviceDaily = (device, start, end) => {

    const params = {
        start,
        end
    };

    return request('get', `devices/${device}/activity_daily_last`, params)
        .then(response => response.data.activity_daily_last || [])
        .catch(error => error)
};
