import CONSTANTES from '../constantes/session';


export const actionFetchLogin = payload => ({
  type: CONSTANTES.FETCHING_USER_LOGIN,
  payload,
});

export const actionFetchLoginSuccess = (payload) => ({
  type: CONSTANTES.FETCHING_USER_LOGIN_SUCCESS,
  payload
});

export const actionFetchLoginFailure = () => ({
  type: CONSTANTES.FETCHING_USER_LOGIN_FAILURE,
});

