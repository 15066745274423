const CONSTANTES = {
  FETCHING_USER_LOGIN: 'FETCHING_USER_LOGIN',
  FETCHING_USER_LOGIN_SUCCESS: 'FETCHING_USER_LOGIN_SUCCESS',
  FETCHING_USER_LOGIN_FAILURE: 'FETCHING_USER_LOGIN_FAILURE',
  FETCHING_USER_LOGOUT: 'FETCHING_USER_LOGOUT',
  FETCHING_USER_LOGOUT_SUCCESS: 'FETCHING_USER_LOGOUT_SUCCESS',
  FETCHING_USER_LOGOUT_FAILURE: 'FETCHING_USER_LOGOUT_FAILURE',
  REMEMBER_ME: 'REMEMBER_ME',
};

export default CONSTANTES;
