import * as actionTypes from '../constantes/activity';

const initialStateActivityRange = {
  payload: [],
  loanding: false,
};

export const reducerFetchActivityRange = (state = initialStateActivityRange, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ACTIVITY_RANGE_DEVICE:
      return { payload: [], loanding: true };
    case actionTypes.FETCHING_ACTIVITY_RANGE_SUCCESS:
      return { payload: action.payload, loanding: false };
    case actionTypes.FETCHING_ACTIVITY_RANGE_FAILURE:
      return { payload: action.payload, loanding: false };
    default:
      return state;
  }
};

const initialStateActivityDaily = {
  payload: [],
  loanding: false,
};

export const reducerFetchActivityDaily = (state = initialStateActivityDaily, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ACTIVITY_DAILY_DEVICE:
      return { payload: [], loanding: true };
    case actionTypes.FETCHING_ACTIVITY_DAILY_SUCCESS:
      return { payload: action.payload, loanding: false };
    case actionTypes.FETCHING_ACTIVITY_DAILY_FAILURE:
      return { payload: action.payload, loanding: false };
    default:
      return state;
  }
};
