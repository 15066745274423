import * as actionTypes from '../constantes/activity';


export const actionFetchRangeDevice = (device, start, end) => ({
  type: actionTypes.FETCHING_ACTIVITY_RANGE_DEVICE,
  device, start, end
});

export const actionFetchRangeDeviceSuccess = payload => ({
  type: actionTypes.FETCHING_ACTIVITY_RANGE_SUCCESS,
  payload,
});

export const actionFetchRangeDeviceFailure = () => ({
  type: actionTypes.FETCHING_ACTIVITY_RANGE_FAILURE,
});


export const actionFetchDailyDevice = (device, start, end) => ({
  type: actionTypes.FETCHING_ACTIVITY_DAILY_DEVICE,
  device, start, end
});

export const actionFetchDailyDeviceSuccess = payload => ({
  type: actionTypes.FETCHING_ACTIVITY_DAILY_SUCCESS,
  payload,
});

export const actionFetchDailyDeviceFailure = () => ({
  type: actionTypes.FETCHING_ACTIVITY_DAILY_FAILURE,
});
