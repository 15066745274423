import {call, put} from 'redux-saga/effects';

import {actionFetchData, actionFetchDataFailure, actionFetchDataSuccess} from '../actions/request';
import {apiDevicesAdd, apiDevicesDetails, apiDevicesUpdate} from '../api/devices';
import {parseDevices} from '../helpers/devices';
import {
  actionFetchAddDeviceFailure,
  actionFetchAddDeviceSuccess,
  actionFetchDevices,
  actionFetchDevicesFailure,
  actionFetchDevicesSuccess,
  actionFetchUpdateDeviceFailure,
  actionFetchUpdateDeviceSuccess
} from '../actions/devices';

export function* sagaDevices() {
  try {
    yield put(actionFetchData());
    let devices = yield call(apiDevicesDetails);
    devices = parseDevices(devices);
    yield put(actionFetchDevicesSuccess(devices));
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchDevicesFailure());
    yield put(actionFetchDataFailure());
  }
}


export function* sagaDeviceAdd({payload}) {
  try {
    yield put(actionFetchData());
    let device = yield call(apiDevicesAdd, payload);
    yield put(actionFetchAddDeviceSuccess(device));
    yield put(actionFetchDevices());
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchAddDeviceFailure());
    yield put(actionFetchDataFailure());

  }
}


export function* sagaDeviceUpdate({id, payload}) {
  try {
    yield put(actionFetchData());
    let device = yield call(apiDevicesUpdate, id, payload);
    yield put(actionFetchUpdateDeviceSuccess(device));
    yield put(actionFetchDevices());
    yield put(actionFetchDataSuccess());
    yield put(actionFetchUpdateDeviceSuccess());
  } catch (error) {
    yield put(actionFetchUpdateDeviceFailure());
    yield put(actionFetchDataFailure());
  }
}


